import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "product",
  initialState: { posts: [], loading: false, error: null },
  reducers: {
    getAllPostsRequest: (state) => {
      state.loading = true;
    },
    getAllPostsSuccess: (state, action) => {
      state.loading = false;
      state.posts = action.payload;
    },
    getAllPostsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAdminProductRequest: (state) => {
      state.loading = true;
    },
    getAdminProductSuccess: (state, action) => {
      state.loading = false;
      state.posts = action.payload;
    },
    getAdminProductFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    clearErrors: (state) => {
      state.error = null;
    },
  },
});
export const {
  getAllPostsRequest,
  getAllPostsSuccess,
  getAllPostsFail,
  getAdminProductRequest,
  getAdminProductSuccess,
  getAdminProductFail,
  clearErrors,
} = productSlice.actions;

export default productSlice.reducer;
