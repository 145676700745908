import axios from "../axios";
import {
  deleteProductRequest,
  deleteProductSuccess,
  updateProductRequest,
  updateProductSuccess,
  deleteProductFail,
  updateProductFail,
} from "../redux/deleteProductReducer";
import {
  getAllPostsRequest,
  getAllPostsSuccess,
  getAllPostsFail,
  getAdminProductRequest,
  getAdminProductSuccess,
  getAdminProductFail,
  clearErrors,
} from "../redux/productReducer";
import {
  newProductRequest,
  newProductSuccess,
  newProductFail,
} from "../redux/newProductReducer";
import {
  getProductDetailsRequest,
  getProductDetailsSuccess,
  getProductDetailsFail,
} from "../redux/productDetailsReducer";
export const getPosts =
  (keyword = "", currentPage = 1, category) =>
  async (dispatch) => {
    try {
      dispatch(getAllPostsRequest());

      let link = `/api/law/posts?keyword=${keyword}&page=${currentPage}`;

      if (category) {
        link = `/api/law/posts?category=${category}&keyword=${keyword}&page=${currentPage}`;
      }

      const { data } = await axios.get(link);

      dispatch(getAllPostsSuccess(data.posts));
    } catch (error) {
      dispatch(getAllPostsFail(error.response.data.message));
    }
  };

export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch(getProductDetailsRequest());

    const { data } = await axios.get(`/api/law/post/${id}`);

    dispatch(getProductDetailsSuccess(data.post));
  } catch (error) {
    dispatch(getProductDetailsFail(error.response.data.message));
  }
};

export const getCreatorProducts = () => async (dispatch) => {
  // try {
  //   dispatch(getCreatorProductRequest());
  //   const { data } = await axios.get("/api/law/posts/creator");
  //   dispatch(getCreatorProductSuccess(data));
  // } catch (error) {
  //   dispatch(getCreatorProductFail(error.response.data.message));
  // }
  return true;
};

export const newProduct = (productData) => async (dispatch) => {
  try {
    dispatch(newProductRequest());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/law/post/new", productData, config);

    dispatch(newProductSuccess(data));
  } catch (error) {
    dispatch(newProductFail(error.response.data.message));
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch(deleteProductRequest());

    const { data } = await axios.delete(`/api/law/post/${id}`);

    dispatch(deleteProductSuccess(data.success));
  } catch (error) {
    dispatch(deleteProductFail(error.response.data.message));
  }
};

export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch(updateProductRequest());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/law/post/${id}`,
      productData,
      config
    );

    dispatch(updateProductSuccess(data.success));
  } catch (error) {
    dispatch(updateProductFail(error.response.data.message));
  }
};

export const getAdminProduct = () => async (dispatch) => {
  try {
    dispatch(getAdminProductRequest());

    const { data } = await axios.get(`/api/law/admin/posts`);

    dispatch(getAdminProductSuccess(data.posts));
  } catch (error) {
    dispatch(getAdminProductFail(error.response.data.message));
  }
};

export const clearProductErrors = () => async (dispatch) => {
  dispatch(clearErrors());
};
