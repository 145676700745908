import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import productReducer from "./redux/productReducer";
import productDetailsReducer from "./redux/productDetailsReducer";
import userReducer from "./redux/userReducer";
import profileReducer from "./redux/profileReducer";
import newProductReducer from "./redux/newProductReducer";
import deleteProductReducer from "./redux/deleteProductReducer";
import allUsersReducer from "./redux/allUsersReducer";
import userDetailsReducer from "./redux/userDetailsReducer";
import forgotPasswordReducer from "./redux/forgotPasswordReducer";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  posts: productReducer,
  postDetails: productDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  createProduct: newProductReducer,
  deleteProduct: deleteProductReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  forgotPassword: forgotPasswordReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
