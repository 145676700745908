import axios from "../axios";
import {
  getAllUsersRequest,
  getAllUsersSuccess,
  getAllUsersFail,
} from "../redux/allUsersReducer";
import {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFail,
} from "../redux/forgotPasswordReducer";
import {
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFail,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFail,
  deleteAccountRequest,
  deleteAccountSuccess,
  deleteAccountFail,
  clearProfileErrors,
  updateUserRequest,
  updateUserSuccess,
  updateUserFail,
} from "../redux/profileReducer";
import {
  getUserDetailsRequest,
  getUserDetailsSuccess,
  getUserDetailsFail,
} from "../redux/userDetailsReducer";
import {
  loginRequest,
  loginSuccess,
  loginFail,
  registerUserRequest,
  registerUserSuccess,
  registerUserFail,
  logoutSuccess,
  logoutFail,
  clearErrors,
} from "../redux/userReducer";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(
      "/api/law/login",
      { email, password },
      config
    );

    dispatch(loginSuccess(data));
  } catch (error) {
    dispatch(loginFail(error.response.data.message));
  }
};

export const register = (userData) => async (dispatch) => {
  try {
    dispatch(registerUserRequest());

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      "/api/law/registration",
      userData,
      config
    );

    dispatch(registerUserSuccess(data));
  } catch (error) {
    dispatch(registerUserFail(error.response.data.message));
  }
};

export const loadUser = async (dispatch, id) => {
  // try {
  //   dispatch(loadUserRequest());
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   const { data } = await axios.get("/api/law/me", id, config);
  //   console.log(data, id);
  //   dispatch(loadUserSuccess(data.user));
  // } catch (error) {
  //   dispatch(loadUserFail(error.response.data.message));
  // }
};

export const logout = () => async (dispatch) => {
  try {
    await axios.get("/api/law/logout");

    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutFail(error.response.data.message));
  }
};

export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch(getUserDetailsRequest());

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.get(`/api/law/admin/user/${id}`, config);

    dispatch(getUserDetailsSuccess(data.user));
  } catch (error) {
    dispatch(getUserDetailsFail(error.response.data.message));
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch(getAllUsersRequest());

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.get(`/api/law/admin/users`, config);

    dispatch(getAllUsersSuccess(data.users));
  } catch (error) {
    dispatch(getAllUsersFail(error.response.data.message));
  }
};

export const clearUserErrors = () => async (dispatch) => {
  dispatch(clearErrors());
};

export const clearProfileError = () => async (dispatch) => {
  dispatch(clearProfileErrors());
};

export const forgotPassword = async (email, dispatch) => {
  try {
    dispatch(forgotPasswordRequest());

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(
      "/api/law/password/forgot",
      { email },
      config
    );

    dispatch(forgotPasswordSuccess(data.message));
  } catch (error) {
    dispatch(forgotPasswordFail(error.response.data.message));
  }
};

export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch(resetPasswordRequest());

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `/api/law/password/reset/${token}`,
      passwords,
      config
    );

    dispatch(resetPasswordSuccess(data.success));
  } catch (error) {
    dispatch(resetPasswordFail(error.response.data.message));
  }
};
export const updateUser = (id, user) => async (dispatch) => {
  try {
    dispatch(updateUserRequest());
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put(`/api/law/admin/user/${id}`, user, config);
    dispatch(updateUserSuccess(data.success));
  } catch (error) {
    dispatch(updateUserFail(error.response.data.message));
  }
};

export const updateProfile = async (userData, dispatch) => {
  try {
    dispatch(updateProfileRequest());

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        withCredentials: true,
      },
    };

    const { data } = await axios.put(
      "/api/law/me/update/info",
      userData,
      config
    );

    dispatch(updateProfileSuccess(data));
    // dispatch(loadUserSuccess(data.user));
  } catch (error) {
    dispatch(updateProfileFail(error.response.data.message));
  }
};

export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch(updatePasswordRequest());

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put("/api/law/me/update", passwords, config);

    dispatch(updatePasswordSuccess(data));
  } catch (error) {
    dispatch(updatePasswordFail(error.response.data.message));
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch(deleteAccountRequest());

    await axios.delete(`/api/law/admin/user/${id}`);

    dispatch(deleteAccountSuccess(true));
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(deleteAccountFail(error.response.data.message));
  }
};
